import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import useInfiniteScroll from '../../hooks/useInfiniteScroll';
import { ApiResponse } from '../../models/ApiResponse';
import { FormConfig } from '../../models/Form';
import { currentClientAtom, currentTenantIdAtom, myClientsAtom } from '../../recoil/atoms/Clients';
import DateUtils from '../../utils/DateUtils';
import DataRow from '../../components/shared/data-grid/DataRow';
import SortableHeading from '../../components/shared/data-grid/SortableHeading';
import Button, { ButtonType } from '../../components/shared/form-control/Button';
import InfoIcon from '../../components/shared/icon/InfoIcon';
import Loader from '../../components/shared/Loader';
import StatusTag, { StatusVariant } from '../../components/shared/tags/StatusTag';
import { Heading, HeadingSize } from '../../components/shared/text/Heading';
import { useTranslation } from 'react-i18next';
import PlusIcon from '../../components/shared/icon/PlusIcon';
import Checkbox from '../../components/shared/form-control/Checkbox';
import { ToastType, useToasts } from '../../contexts/ToastContext';
import TemplateFormService from '../../services/TemplateFormService';
import LanguageUtils from '../../utils/LanguageUtils';
import { ModalContext } from '../../contexts/ModalContext';
import ConfirmationModal from '../../components/shared/modal/variants/ConfirmationModal';
import StandardModal from '../../components/shared/modal/variants/StandardModal';
import { Feature, useFeatureFlags } from '../../contexts/FeatureFlagContext';

type ResourceTemplateListProps = {
  resourcesPaged: ApiResponse<FormConfig[]>;
  onArchive: (formId: string) => void;
  onRestore: (formId: string) => void;
  onLoadMore?: (pageNumber: number) => void;
  sortBy: string;
  onSort?: (expression: string) => void;
  isLoading: boolean;
};

const ResourceTemplateList: FC<ResourceTemplateListProps> = (props) => {
  const { featureFlags } = useFeatureFlags();
  const { resourcesPaged, onArchive, onRestore, onLoadMore, onSort, sortBy, isLoading } = props;
  const [sortExpressions, setSortExpressions] = useState<Record<string, string>>({});
  const navigate = useNavigate();
  const [showRestoreConfim, setShowRestoreConfirm] = useState(false);
  const [showArchiveConfim, setShowArchiveConfirm] = useState(false);
  const [selectedResource, setSelectedResource] = useState<FormConfig | null>(null);
  const currentPage = resourcesPaged.pageNumber || 0;
  const totalPages = resourcesPaged.totalPages || 0;
  const [sharingForm, setSharingForm] = useState<FormConfig | null>(null);
  const [sharingWithListOriginal, setSharingWithListOriginal] = useState<string[] | null>(null);
  const [sharingWithList, setSharingWithList] = useState<string[] | null>(null);
  const currentClient = useRecoilValue(currentClientAtom);
  const currentTenantId = useRecoilValue(currentTenantIdAtom);
  const myClients = useRecoilValue(myClientsAtom);
  const { t } = useTranslation(['resources', 'common']);
  const toasts = useToasts();

  const updateSortExpression = (field: string, expression: string) => {
    const expressions = { ...sortExpressions, [field]: expression };
    setSortExpressions(expressions);

    const fullExpression = Object.values(expressions).filter((x) => x !== expression);
    fullExpression.unshift(expression); // move to first

    onSort && onSort(fullExpression.join(','));
  };

  useEffect(() => {
    let expressions = {};
    for (const expression of sortBy.split(',')) {
      expressions = { ...expressions, [expression.replace(/[-+]/g, '')]: expression };
    }
    setSortExpressions(expressions);
  }, [sortBy]);

  const [lastElementRef] = useInfiniteScroll(currentPage < totalPages ? () => onLoadMore && onLoadMore(currentPage + 1) : null, isLoading);

  useEffect(
    function updateFormShares() {
      if (!featureFlags.moduleSharing) {
        return;
      }
      if (!sharingForm) {
        setSharingWithList(null);
        setSharingWithListOriginal(null);
        return;
      }

      TemplateFormService.getShares(sharingForm.id).then((res) => {
        setSharingWithList(res.data);
        setSharingWithListOriginal(res.data);
      });
    },
    [featureFlags.moduleSharing, sharingForm],
  );

  const onShareWithChanged = useCallback((clientId: string, active: boolean) => {
    if (active) {
      setSharingWithList((prev) => prev && [...prev, clientId]);
    } else {
      setSharingWithList((prev) => prev && prev.filter((x) => x !== clientId));
    }
  }, []);

  const saveSharedForms = useCallback(() => {
    if (!sharingForm?.id) {
      return;
    }

    const addedClientIds = sharingWithList?.filter((x) => !sharingWithListOriginal?.includes(x)) ?? [];
    const removedClientIds = sharingWithListOriginal?.filter((x) => !sharingWithList?.includes(x)) ?? [];

    toasts.addToast({ title: t('resources:toasters.sharing.title'), type: ToastType.SUCCESS, expiresInMs: 7000 });
    for (const clientId of addedClientIds) {
      TemplateFormService.shareWithClient(sharingForm.id, sharingForm.version, clientId);
    }

    for (const clientId of removedClientIds) {
      TemplateFormService.revokeSharingWithClient(sharingForm.id, sharingForm.version, clientId);
    }

    setSharingForm(null);
  }, [sharingForm?.id, sharingForm?.version, sharingWithList, sharingWithListOriginal, t, toasts]);

  const myTenants = useMemo(() => myClients.filter((x) => x.isTenant && x.id !== currentTenantId), [currentTenantId, myClients]);

  return (
    <div>
      <div className="flex w-full items-end border-2 border-transparent pl-4">
        <div className="w-6/12">
          <div className="w-12">{/* SPACER */}</div>
          <SortableHeading
            title={t('resources:template-list.heading.name')}
            onSort={updateSortExpression}
            expression={sortExpressions['title'] ?? '+title'}
          />
        </div>
        <div className="w-2/12">Status</div>
        <div className="w-4/12">
          <SortableHeading
            title={t('resources:template-list.heading.modified')}
            onSort={updateSortExpression}
            expression={sortExpressions['modifiedUtc'] ?? '+modifiedUtc'}
          />
        </div>
        <div className="mr-2 w-10">{/* SPACER */}</div>
        <div className="w-8">{/* SPACER */}</div>
      </div>
      {resourcesPaged.data.map((resource, i) => {
        const isLast = resourcesPaged.data.length === i + 1;
        return (
          <div key={resource.id} ref={isLast ? lastElementRef : undefined}>
            <DataRow
              data-cy={`resource-${resource.id}`}
              key={resource.id}
              url={
                !currentClient?.id
                  ? `/builder/${currentTenantId}/resource-builder/${resource.id}/${resource.version}`
                  : `/clients/${currentClient.id}/resource-builder/${resource.id}/${resource.version}`
              }
              contextMenuItems={[
                {
                  title: resource.archivedUtc ? t('resources:template-list.menu.restore') : t('resources:template-list.menu.archive'),
                  onClick: () => {
                    setSelectedResource(resource);
                    if (resource.archivedUtc) {
                      setShowRestoreConfirm(true);
                    } else {
                      setShowArchiveConfirm(true);
                    }
                  },
                },
                {
                  title: t('resources:template-list.menu.share'),
                  onClick: () => {
                    setSharingForm(resource);
                  },
                  hide: !myTenants.length || !featureFlags.moduleSharing,
                },
              ]}
            >
              <div className="flex w-6/12 items-center text-left">
                <div>
                  <div className="text-color-2 font-medium">{LanguageUtils.getTranslation('title', resource.translations)}</div>
                  <div className="text-color-3 text-dpm-12">{resource.code}</div>
                </div>
              </div>
              <div className="flex w-2/12 justify-items-start pr-2">
                {resource.archivedUtc ? (
                  <StatusTag data-cy="archived" text={t('resources:status.archived')} statusType={StatusVariant.RED} />
                ) : (
                  <StatusTag data-cy="active" text={t('resources:status.active')} statusType={StatusVariant.GREEN} />
                )}
              </div>
              <div className="w-2/12">{DateUtils.formatDateTime(new Date(resource.modifiedUtc))}</div>
            </DataRow>
          </div>
        );
      })}
      {isLoading && (
        <div className="flex flex-col items-center py-6">
          <Loader size={16} centered={false} />
        </div>
      )}
      {resourcesPaged.data.length === 0 && (
        <div data-cy="resource-template-list-empty" className="flex h-full justify-center">
          <div className="mt-36 text-center">
            <InfoIcon className="bg-primary-1 text-primary-1 my-2 h-16 w-16 rounded-full bg-opacity-10 p-4" />
            <Heading size={HeadingSize.H3} className="my-4">
              {t('resources:template-list.empty.heading')}
            </Heading>
            <div className="text-dpm-20 pb-5">{t('resources:template-list.empty.sub-heading')}</div>
            <Button
              onClick={() =>
                currentClient ? navigate(`/clients/${currentClient.id}/resource-builder`) : navigate(`/builder/${currentTenantId}/resource-builder`)
              }
              type={ButtonType.PRIMARY}
              data-cy="create-new-resource"
            >
              <PlusIcon className="mr-2 w-4" /> {t('resources:buttons.create')}
            </Button>
          </div>
        </div>
      )}

      {/* archive confirm modal */}
      <ModalContext.Provider value={{ open: showArchiveConfim, onClose: () => setShowArchiveConfirm(false) }}>
        <ConfirmationModal
          title={t('resources:modals.archive.heading')}
          description={
            selectedResource
              ? t('resources:modals.archive.body', { title: LanguageUtils.getTranslation('title', selectedResource.translations) })
              : ''
          }
          cancelText={t('resources:modals.archive.buttons.cancel')}
          confirmText={t('resources:modals.archive.buttons.confirm')}
          onCancel={() => setShowArchiveConfirm(false)}
          onConfirm={() => {
            if (selectedResource) {
              onArchive(selectedResource?.id);
              setShowArchiveConfirm(false);
            }
          }}
          alt
        />
      </ModalContext.Provider>

      {/* restore modal */}
      <ModalContext.Provider value={{ open: showRestoreConfim, onClose: () => setShowRestoreConfirm(false) }}>
        <ConfirmationModal
          title={t('resources:modals.restore.heading')}
          description={
            selectedResource
              ? t('resources:modals.restore.body', { title: LanguageUtils.getTranslation('title', selectedResource.translations) })
              : ''
          }
          cancelText={t('resources:modals.restore.buttons.cancel')}
          confirmText={t('resources:modals.restore.buttons.confirm')}
          onCancel={() => setShowRestoreConfirm(false)}
          onConfirm={() => {
            if (selectedResource) {
              onRestore(selectedResource?.id);
              setShowRestoreConfirm(false);
            }
          }}
          alt
        />
      </ModalContext.Provider>

      {/* Sharing modal */}
      <Feature authorizedFlags={['moduleSharing']}>
        <ModalContext.Provider value={{ open: !!sharingForm, onClose: () => setSharingForm(null), modalWidth: 'w-2/5' }}>
          <StandardModal
            title={t('resources:modals.sharing.title', {
              form: LanguageUtils.getTranslation('title', sharingForm?.translations || {}),
            })}
            subTitle={t('resources:modals.sharing.help-text')}
            confirmButtonTitle={t('resources:modals.sharing.save-button')}
            onCancelClick={() => setSharingForm(null)}
            onConfirmClick={saveSharedForms}
            confirmDisabled={!sharingWithList}
          >
            <div className="flex max-h-[50vh] flex-col">
              {sharingWithList ? (
                <>
                  <div className="my-4 flex-shrink overflow-auto px-2">
                    {myTenants.map((x, i) => (
                      <div key={x.id} className={`flex items-center justify-between gap-4 ${i === 0 ? '' : 'border-t'}`}>
                        <div>{x.name}</div>
                        <Checkbox slider value={!!sharingWithList.find((id) => id === x.id)} onChange={(val) => onShareWithChanged(x.id, val)} />
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <div className="relative min-h-12">
                  <Loader size={10} />
                </div>
              )}
            </div>
          </StandardModal>
        </ModalContext.Provider>
      </Feature>
    </div>
  );
};

export default ResourceTemplateList;
